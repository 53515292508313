import { createContext, ReactNode } from "react"
import { Account } from "./types"
import { SupabaseClient } from "@supabase/supabase-js"

export interface AppContextProps {
  supabase: SupabaseClient
  account: Account | null
}

export const AppContext = createContext<AppContextProps | undefined>(undefined)

export function AppProvider({
  children,
  supabase,
  account,
}: {
  children: ReactNode
  supabase: SupabaseClient
  account: Account | null
}) {
  return (
    <AppContext.Provider value={{ supabase, account }}>
      {children}
    </AppContext.Provider>
  )
}
